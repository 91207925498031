<template>
  <div>
    <v-form
      class="sticky-payin z-100"
      ref="form"
      v-model="valid"
      @submit.prevent="getPayinDataFromApi()"
      lazy-validation
    >
      <div class="d-flex flex-wrap align-center">
        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">From Date *</div>
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu1 = true"
                class="apiKeyInput"
                v-model="computedDateFormatted1"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="fromDateRules"
                placeholder="Select the From Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              :show-current="true"
              no-title
              @input="dateMenu1 = false"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">To Date *</div>
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu2 = true"
                class="apiKeyInput"
                v-model="computedDateFormatted2"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="toDateRules"
                placeholder="Select the To Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              :show-current="true"
              no-title
              @input="dateMenu2 = false"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">From Amount</div>
          <v-text-field
            outlined
            v-model="fromAmount"
            dense
            height="40"
          ></v-text-field>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">To Amount</div>
          <v-text-field
            outlined
            v-model="toAmount"
            dense
            height="40"
          ></v-text-field>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">Status</div>
          <v-text-field
            outlined
            v-model="paymentStatus"
            dense
            height="40"
          ></v-text-field>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">User ID</div>
          <v-text-field
            outlined
            v-model="userID"
            dense
            height="40"
          ></v-text-field>
        </div>

        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">Exchange Segment</div>
          <v-text-field
            outlined
            v-model="exchangeSeg"
            dense
            height="40"
          ></v-text-field>
        </div>
        <div class="px-4 pt-4">
          <div class="pb-1 fsize12">Payment Method</div>
          <v-text-field
            outlined
            v-model="paymentMethod"
            dense
            height="40"
          ></v-text-field>
        </div>
        <v-btn
          type="submit"
          height="40"
          depressed
          color="primary"
          class="text-capitalize fsize12 mt-3"
          >Submit</v-btn
        >
        <v-btn
          height="40"
          width="80"
          v-if="getPayinData.length > 0"
          @click="DownloadNewFormet(getPayinData, 'payin_data')"
          depressed
          min-width="fit-content"
          color="blue"
          class="text-capitalize white--text fsize12 ml-2 mt-3"
        >
          Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
        </v-btn>
        <div class="payinSwitches">
          <v-switch
            @click="handleChangeBoRMS($event)"
            v-model="isRmsBoSwitch"
            dense
            label="BO/RMS"
          ></v-switch>
          <v-switch
            @click="handleChangeRms($event)"
            v-model="isRmsSwitch"
            dense
            label="RMS"
          ></v-switch>
        </div>
      </div>
    </v-form>
    <div class="">
      <v-data-table
        v-if="getPayinData.length > 0"
        :headers="headers"
        :items="getPayinData"
        :items-per-page="10"
        class="elevation-1 mt-2"
      >
        <template v-slot:item="props">
          <tr class="pointer">
            <td class="text-center">{{ props.item.createdOn }}</td>
            <td class="text-center">{{ props.item.userId }}</td>
            <td class="text-center">
              {{ props.item.orderId }}
            </td>
            <td class="text-center">
              {{ props.item.amount }}
            </td>
            <td class="text-center">
              {{ props.item.segment }}
            </td>
            <td class="text-center">
              {{ props.item.paymentMethod }}
            </td>
            <td class="text-center">
              {{ props.item.bankName }}
            </td>
            <td class="text-center">
              {{ props.item.ifsc ? props.item.ifsc : "NA" }}
            </td>
            <td class="text-center">
              <v-btn
                @click="handleStatus(props.item)"
                height="24"
                width="80"
                depressed
                :color="props.item.boUpdate == '1' ? '#62ab74' : '#da4e4e'"
                class="text-capitalize fsize12 white--text"
                >{{ props.item.boUpdate == "1" ? "Success" : "Failed" }}</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- <v-simple-table class="ma-4" v-if="getPayinData.length != 0">
      <thead>
        <tr>
          <th class="text-center">Date</th>
          <th class="text-center">userId</th>
          <th class="text-center">Transaction ID</th>
          <th class="text-center">Amount</th>
          <th class="text-center">Segment</th>
          <th class="text-center">Payment Method</th>
          <th class="text-center">Bank Name</th>
          <th class="text-center">RMS</th> 
          <th class="text-center">BO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in getPayinData" :key="idx">
          <td class="text-center">{{ i.createdOn }}</td>
          <td class="text-center">{{ i.userId }}</td>
          <td class="text-center">{{ i.orderId }}</td>
          <td class="text-center">
            {{ i.amount }}
          </td>
          <td class="text-center">
            {{ i.segment }}
          </td>
          <td class="text-center">
            {{ i.paymentMethod }}
          </td>
          <td class="text-center">
            {{ i.bankName }}
          </td>
          <!-- <td class="input_text text-center">
              <v-btn @click="handleStatus(i)" height="24" width="80" depressed :color="i.rmsUpdate == '1' ? '#62ab74' : '#da4e4e'" class="text-capitalize fsize12 white--text">{{ i.rmsUpdate == '1' ? 'Success' : 'Failed' }}</v-btn>
            </td> -->
    <!-- <td class="text-center">
            <v-btn
              @click="handleStatus(i)"
              height="24"
              width="80"
              depressed
              :color="i.boUpdate == '1' ? '#62ab74' : '#da4e4e'"
              class="text-capitalize fsize12 white--text"
              >{{ i.boUpdate == "1" ? "Success" : "Failed" }}</v-btn
            >
          </td>
        </tr>
      </tbody> 
    </v-simple-table> -->

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-2">
        <div class="my-3">
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">User ID:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.userId }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Voucher No:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{
                getCurrentRowData.voucherNo ? getCurrentRowData.voucherNo : "NA"
              }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Account No:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.accountNo }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Count:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.count }}
            </div>
          </div>
          <!-- <div class="d-flex align-center mb-3">
              <div class="fsize12 secondaryColor">RMS Status: </div>
              <div class="d-flex align-center fsize12 primaryColor mx-3">
                {{ getCurrentRowData.rmsUpdate == '1' ? 'Success' : 'Failed' }}
                <v-btn @click="retryRmsUpdate()" :loading="getRmsUpdateLoader" depressed height="26" width="60" class="text-capitalize fsize12 mx-3" v-if="getCurrentRowData.rmsUpdate == '0'">Retry</v-btn>
              </div>
            </div> -->
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">BO Status :</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.boUpdate == "1" ? "Success" : "Failed" }}
              <v-btn
                @click="retryBoUpdate()"
                :loading="getBoUpdateLoader"
                depressed
                height="26"
                width="60"
                class="text-capitalize fsize12 mx-3"
                v-if="getCurrentRowData.boUpdate == '0'"
                >Retry</v-btn
              >
            </div>
          </div>
        </div>
        <div
          class="my-2 border pa-2 rounded-lg"
          dense
          v-for="(i, idx) in getPayinResponse"
          v-if="getPayinResponse.length != 0"
        >
          <div class="fsize12">
            <div class="secondaryColor">Request :</div>
            <div class="primaryColor resp-data">
              <json-viewer :value="i.request" sort></json-viewer>
            </div>
          </div>
          <div class="fsize12">
            <div class="secondaryColor">Response :</div>
            <div class="primaryColor resp-data">
              <json-viewer :value="i.response" sort></json-viewer>
            </div>
          </div>
          <div class="fsize12">
            <div class="secondaryColor">Error Message :</div>
            <div class="primaryColor resp-data">
              <json-viewer
                :value="i.errorMsg ? i.errorMsg : 'NA'"
                sort
              ></json-viewer>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);
import commonFunctions from "../../mixins/commonFunctions";
export default {
  data() {
    return {
      valid: true,
      dateMenu1: false,
      dateMenu2: false,
      // fromDate: null,
      // toDate: null,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),

      payinData: [],
      headers: [
        { text: "Date", align: "center", value: "date", sortable: true },
        { text: "userId", align: "center", value: "userId", sortable: true },
        {
          text: "Transaction ID",
          align: "center",
          value: "transactionId",
          sortable: true,
        },
        { text: "Amount", align: "center", value: "amount", sortable: true },
        { text: "Segment", align: "center", value: "segment", sortable: true },
        {
          text: "Payment Method",
          align: "center",
          value: "paymentMethod",
          sortable: true,
        },
        {
          text: "Bank Name",
          align: "center",
          value: "bankName",
          sortable: true,
        },
        {
          text: "IFSC",
          align: "center",
          value: "ifsc",
          sortable: true,
        },
        { text: "BO", align: "center", value: "boUpdate", sortable: true },
      ],
      dialog: false,

      fromAmount: "",
      toAmount: "",
      paymentStatus: "",
      userID: "",
      paymentMethod: "",
      exchangeSeg: "",
      paymentMethod: "",

      fromDateRules: [(v) => !!v || "From Date is required"],
      toDateRules: [(v) => !!v || "To Date is required"],
      fromAmountRules: [(v) => !!v || "From Amount is required"],
      toAmountRules: [(v) => !!v || "To Amount is required"],
    };
  },
  mixins:[commonFunctions],
  computed: {
    ...mapGetters("payin", [
      "getPayinData",
      "getPayinResponse",
      "getRmsUpdateLoader",
      "getBoUpdateLoader",
      "getCurrentRowData",
      "getPayinLastPayload",
      "getIsRmsSwitch",
      "getIsRmsBoSwitch",
    ]),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate, '-');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate, '-');
      },
      set() {},
    },
    isRmsSwitch: {
      get() {
        return this.getIsRmsSwitch;
      },
      set(value) {
        this.$store.commit("payin/setIsRmsSwitch", value);
      },
    },
    isRmsBoSwitch: {
      get() {
        return this.getIsRmsBoSwitch;
      },
      set(value) {
        this.$store.commit("payin/setIsRmsBoSwitch", value);
      },
    },
  },
  methods: {
    DownloadNewFormet(value, fileName) {
      const modifyData = [];
      var tableHead = "";
      for (let item of value) {
        tableHead = {
          "User Id": item.userId,
          "Order Id": item.orderId,
          "Receipt Id": item.receiptId,
          "Bank Name": item.bankName,
          "Account No": item.accountNo,
          Segment: item.segment,
          "Payment Method": item.paymentMethod,
          "UPI ID": item.upiId,
          Amount: item.amount,
          // Request: item.request,
          "Payment Status": item.paymentStatus,
          "BO Update": item.boUpdate,
          "Voucher No": item.voucherNo,
          "Created On": item.createdOn,
          Count: item.count,
          "RMS Update": item.rmsUpdate,
          IFSC: item.ifsc,
        };
        modifyData.push(tableHead);
      }

      // get keys as array
      const keys = Object.keys(modifyData[0]);
      const commaSeparatedString = [
        keys.join(","),
        modifyData
          .map((row) => keys.map((key) => row[key]).join(","))
          .join("\n"),
      ].join("\n");
      const csvBlob = new Blob([commaSeparatedString]);

      const a2 = document.createElement("a");
      var tempFileName = fileName + ".csv";
      a2.download = tempFileName;
      a2.href = URL.createObjectURL(csvBlob);
      a2.target = "_blank";
      a2.click();
    },
    async getPayinDataFromApi() {
      if (this.$refs.form.validate()) {
        let json = {
          paymentStatus: this.paymentStatus ? this.paymentStatus : "%",
          fromDate: this.fromDate,
          toDate: this.toDate,
          offSet: 0,
          userId: this.userID ? this.userID : "%",
          fromAmount: this.fromAmount ? this.fromAmount : "0",
          toAmount: this.toAmount ? this.toAmount : "1000000000",
          exchSeg: this.exchangeSeg ? this.exchangeSeg : "%",
          paymentMethod: this.paymentMethod ? this.paymentMethod : "%",
        };
        // await this.$store.dispatch('payin/getPayinData', json)
        await this.$store.dispatch("payin/getPaymentDetailsDownload", json);
      }
    },
    getPayinResponseFromApi(data) {
      let json = {
        orderId: data.orderId,
        userId: data.userId,
      };
      this.$store.dispatch("payin/getPayinResponse", json);
    },
    handleStatus(data) {
      this.dialog = true;
      this.$store.commit("payin/setCurrentRowData", data);
      this.getPayinResponseFromApi(data);
    },
    retry() {},
    async retryRmsUpdate() {
      let json = {
        orderId: this.getCurrentRowData?.orderId,
        userId: this.getCurrentRowData?.userId,
      };
      await this.$store.dispatch("payin/payinRmsRetry", json);
      // await this.$store.dispatch('payin/getPayinData', this.getPayinLastPayload)
      await this.$store.dispatch(
        "payin/getPaymentDetailsDownload",
        this.getPayinLastPayload
      );
      if (this.getPayinData?.length) {
        let crow = "";
        this.getPayinData.forEach((el) => {
          if (el.orderId == json.orderId && el.userId == json.userId) {
            crow = el;
          }
        });
        this.$store.commit("payin/setCurrentRowData", crow);
      }
    },
    async retryBoUpdate() {
      let json = {
        orderId: this.getCurrentRowData?.orderId,
        userId: this.getCurrentRowData?.userId,
      };
      await this.$store.dispatch("payin/payinBoRetry", json);
      // await this.$store.dispatch('payin/getPayinData', this.getPayinLastPayload)
      await this.$store.dispatch(
        "payin/getPaymentDetailsDownload",
        this.getPayinLastPayload
      );
      if (this.getPayinData?.length) {
        let crow = "";
        this.getPayinData.forEach((el) => {
          if (el.orderId == json.orderId && el.userId == json.userId) {
            crow = el;
          }
        });
        this.$store.commit("payin/setCurrentRowData", crow);
      }
    },
    async handleChangeRms(e) {
      if (this.isRmsSwitch) {
        this.$store.dispatch("payin/disableRms");
      } else {
        this.$store.dispatch("payin/enableRms");
      }
    },
    async handleChangeBoRMS(e) {
      if (this.isRmsBoSwitch) {
        this.$store.dispatch("payin/disableBORms");
      } else {
        this.$store.dispatch("payin/enableBORms");
      }
    },
  },
  created() {
    this.$store.dispatch("payin/getRmsBoStatus");
  },
};
</script>
<style>
.sticky-payin {
  position: sticky !important;
  top: 148px !important;
  background-color: #ffffff;
}
.z-100 {
  z-index: 100 !important;
}
</style>
